<template>
  <div id="app">
    <NavBar v-if="isLoggedIn" @logout="handleLogout" />
    <router-view />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import sessionMixin from './mixins/sessionMixin.js';

export default {
  name: 'App',
  mixins: [sessionMixin],
  components: {
    NavBar
  },
  methods: {
    handleLogout() {
      this.isLoggedIn = false; // Update global login state on logout
    }
  }
};
</script>

<style scoped>
/* Your existing styles */
</style>
