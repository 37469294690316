<template>
  <div class="d-flex flex-column h-100 text-center">
    <main class="container">
      <div class="logo-wrapper logo-small">
        <div class="image-container">
          <img src="../img/logo-swissntet-pdp.png" alt="Logo">
        </div>
      </div>
      <div class="starter-template text-center py-1 px-3">
        <div class="card text-center">
          <div class="card-body">
            <h2>Ingresar DNI del titular</h2>
            <br />
            <form @submit.prevent="handleFormSubmission">
              <div class="row">
                <div class="col">
                  <input
                    id="input1"
                    class="input form-control form-control-lg"
                    placeholder="DNI"
                    type="text"
                    required
                    autocomplete="off"
                    v-model="dni"
                    @input="validateDNI"
                  />
                  <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                </div>
              </div>
            </form>
            <br /><br />
            <div class="btn-group">
              <button class="btn-cancelar ml-2 rounded" @click="goHome">
                <strong>&nbsp;&nbsp;CANCELAR&nbsp;&nbsp;</strong>
              </button>
              <button class="btn ml-2 rounded" :disabled="!isDniValid" @click="handleFormSubmission">
                <strong>&nbsp;&nbsp;ACEPTAR&nbsp;&nbsp;</strong>
              </button>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { guardaDatosCliente } from '@/utils/funcionesCompartidas.js';
import sessionMixin from '@/mixins/sessionMixin'; // Import the session mixin

export default {
  mixins: [sessionMixin], // Include the session mixin
  data() {
    return {
      dni: '',
      errorMessage: '',
      isDniValid: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    async handleFormSubmission() {
      if (this.isDniValid) {
        await this.guardaClienteSeleccionado();
      } else {
        this.errorMessage = "Por favor, ingrese un DNI válido.";
      }
    },
    async guardaClienteSeleccionado() {
      localStorage.setItem('usernameingresado', this.dni);
      const idCliente = localStorage.getItem('usernameingresado');
      await guardaDatosCliente(idCliente);
      this.$router.push('/confirmaCliente');
    },
    validateDNI() {
      const regex = /^\d{8}$/;
      this.isDniValid = regex.test(this.dni);
      this.errorMessage = this.isDniValid ? '' : 'El DNI debe contener exactamente 8 dígitos numéricos.';
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('input1').focus();
    });
  },
};
</script>


<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/login.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';
</style>
