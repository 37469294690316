<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <img src="../img/iso-swissnet-512.png" alt="SwissNet" height="30">
    </div>
    <ul class="navbar-menu" v-if="isLoggedIn">
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('')">Inicio</button>
      </li>
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('seleccionaCliente')">Pagar</button>
      </li>
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('listarOperaciones')">Listar Operaciones</button>
      </li>
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('cierreDeCaja')">Cierre de Caja</button>
      </li>
    </ul>
    <div class="navbar-session" v-if="isLoggedIn">
      <span>{{ userName }}</span>
      <button class="btn-small mr-2 mb-2" @click="logout">Cerrar Sesión</button>
    </div>
  </nav>
</template>

<script>
import sessionMixin from '@/mixins/sessionMixin.js';
import navigationMixin from '@/mixins/navigationMixin.js'; // Import the navigation mixin

export default {
  mixins: [sessionMixin, navigationMixin], // Include the mixin for goTo
  data() {
    return {
      userName: localStorage.getItem('userName') || 'Usuario',
    };
  },
  computed: {
    isAdmin() {
      return this.userRole === 'admin';
    }
  },
  methods: {
    logout() {
      // Clear session and redirect to login
      localStorage.clear();
      this.$router.push('/login');
    },
  },
  mounted() {
    this.userName = localStorage.getItem('userName') || 'Usuario';
  }
};
</script>

<style scoped>
/* Minimal styling for now */
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f9fa; /* Light background */
  border-bottom: 1px solid #ddd;
}

.navbar-menu {
  display: flex;
  list-style: none;
  padding: 0;
}

.navbar-menu li {
  margin-right: 1rem;
}

.navbar-session {
  display: flex;
  align-items: center;
}

.navbar-session span {
  margin-right: 10px;
}

button {
  cursor: pointer;
  background-color: #d54e92;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.btn-small {
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
}
</style>
