<template>
  <div class="dashboard-container">
    <div class="row">
      <!-- Left Column: User Selection and Summary -->
      <div class="col-md-6">
        <!-- Admin Only: User Selection -->
        <div v-if="isAdmin" class="user-selection datoscliente">
          <h3>Seleccionar Usuario</h3>
          <label for="user-select">Usuario:</label>
          <select v-model="selectedUserId" @change="fetchDashboardData" id="user-select" class="form-control">
            <option value="">Selecciona un usuario</option>
            <option v-for="user in users" :key="user.userid" :value="user.userid">{{ user.nombre }}</option>
          </select>
        </div>

        <!-- ResumenCierreCaja Component to show total open amount, commission, and remaining amount -->
        <ResumenCierreCaja
          :userId="selectedUserId || getUserId()"
          :numOperations="totalOpenOperations"
          :totalAmount="totalAmount"
          :commissionAmount="commissionAmount"
        />
        
        <!-- Button to close operations -->
        <div class="btn-group">
          <button class="btn-cancelar ml-2 rounded" @click="goToOperations">Ver Todas las Operaciones</button>
          <button class="btn ml-2 rounded" @click="confirmCloseOperations" :disabled="!selectedUserId && isAdmin">Cerrar Caja</button>
        </div>
      </div>

      <!-- Right Column: Last 10 Operations -->
      <div class="col-md-6">
        <div v-if="lastOperations.length > 0" class="datoscliente">
          <h3>Últimas 10 Operaciones</h3>
          <ul class="list-group mb-3">
            <li v-for="operation in lastOperations" :key="operation.idoperacion" class="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h2 class="my-0">ID: {{ operation.idoperacion }}</h2>
                <small class="text-muted">Monto: ${{ operation.importe }} - Estado: {{ operation.status }} - Fecha: {{ operation.fecha }}</small>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <p>No hay operaciones disponibles.</p>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div v-if="showConfirmationModal" class="modal">
      <div class="modal-content">
        <h3 class="heading-3">Confirmar Cierre</h3>
        <!-- ResumenCierreCaja Component inside the modal -->
        <ResumenCierreCaja
          :userId="selectedUserId || getUserId()"
          :numOperations="totalOpenOperations"
          :totalAmount="totalAmount"
          :commissionAmount="commissionAmount"
        />

        <!-- Buttons for Confirm and Cancel -->
        <div class="btn-group">
          <button class="btn-cancelar" @click="closeModal">Cancelar</button>
          <button class="btn" @click="closeOperations">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResumenCierreCaja from '@/components/ResumenCierreCaja.vue'; // Import the component
import sessionMixin from '@/mixins/sessionMixin.js'; // Session management mixin

export default {
  mixins: [sessionMixin],
  components: {
    ResumenCierreCaja // Register the component
  },
  data() {
    return {
      totalAmount: 0,
      commissionAmount: 0,
      totalOpenOperations: 0, // Number of open operations
      lastOperations: [],
      errorMessage: '',
      showConfirmationModal: false,
      selectedUserId: '', // For admin to select a user
      users: [] // List of users (only for admins)
    };
  },
  computed: {
    isAdmin() {
      return this.userRole === 'admin';
    },
    isLoggedIn() {
      return !!this.userRole;
    }
  },
  methods: {
    getUserId() {
      return localStorage.getItem('userid');
    },
    async fetchDashboardData() {
      const userId = this.isAdmin ? this.selectedUserId : this.getUserId();

      if (!userId) {
        this.errorMessage = this.isAdmin ? 'Por favor seleccione un usuario' : 'Falta el User ID. Por favor, inicie sesión de nuevo.';
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/dashboard?userId=${userId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include'
        });

        if (!response.ok) throw new Error('Failed to fetch dashboard data');

        const data = await response.json();
        this.totalAmount = data.totalAmount || 0;
        this.commissionAmount = data.commissionAmount || 0;
        this.lastOperations = data.lastOperations || [];

        // Calculate the number of open operations
        const openOperations = this.lastOperations.filter(op => op.status === 'open');
        this.totalOpenOperations = openOperations.length;

        if (this.isAdmin) {
          this.fetchUsers();
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        this.errorMessage = 'Failed to load dashboard data. Please try again.';
      }
    },

    // Fetch the list of users (for admin selection)
    async fetchUsers() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/users`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include'
        });
        if (!response.ok) throw new Error('Failed to fetch users');

        const data = await response.json();
        this.users = data.users;
      } catch (error) {
        console.error('Error fetching users:', error);
        this.errorMessage = 'Failed to load users. Please try again.';
      }
    },

    goToOperations() {
      this.$router.push('/listarOperaciones');
    },

    confirmCloseOperations() {
      this.showConfirmationModal = true;
    },

    closeModal() {
      this.showConfirmationModal = false;
    },

    async closeOperations() {
  const userid = this.isAdmin ? this.selectedUserId : localStorage.getItem('userid');  // Using lowercase 'userid'

  console.log('Retrieved User ID:', userid);  // Log the retrieved userid

  if (!userid) {
    console.error('User ID is missing from localStorage or selection.');
    this.errorMessage = 'User ID is missing. Please log in again.';
    return;
  }

  const payload = {
    userid: userid  // Send 'userid' to match the backend's expected format
  };

  console.log('Close Operations Payload:', payload);

  try {
    console.log("Sending close operations request...");
    const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/closeOperations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('apiKeyPuntosdepago'),
        'user-role': localStorage.getItem('userRole')  // Send user role in the headers
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    });

    const responseBody = await response.json();
    console.log("Close operations response status:", response.status);
    console.log("Close operations response body:", responseBody);

    if (!response.ok) {
      throw new Error('Failed to close operations');
    }

    console.log("Operation Closed Successfully:", responseBody);
    this.$router.push('/cierreDeCajaReceipt');

  } catch (error) {
    console.error('Error closing operations:', error);
    this.errorMessage = 'Failed to close operations. Please try again.';
  }
}



  ,

    
  },
  mounted() {
    this.fetchDashboardData();
    if (this.isAdmin) {
      this.fetchUsers();
    }
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

/* Styling for the two-column layout */
.row {
  margin-top: 20px;
}

.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
}

.btn-group {
  margin-top: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.heading-3 {
  font-size: 1.8rem;
  color: #2963a7;
}

.modal .btn-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal .btn {
  font-size: 1.2rem;
  padding: 10px 20px;
}
</style>
