<template>
  <div class="datoscliente">
    <h3>Resumen del Cierre</h3>
    <p><strong>Usuario:</strong> {{ userId }}</p>
    <p><strong>Fecha de Cierre:</strong> {{ formattedDate }}</p>
    <p><strong>Número de operaciones cerradas:</strong> {{ numOperations }}</p>
    <p><strong>Monto total:</strong> ${{ totalAmount }}</p>
    <p><strong>Monto de comisión:</strong> ${{ commissionAmount }}</p>
    <p><strong>Monto a rendir:</strong> ${{ totalAmount - commissionAmount }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: '',
      formattedDate: '',
      numOperations: 0,
      totalAmount: 0,
      commissionAmount: 0
    };
  },
  mounted() {
    // Get the query parameters from the router
    console.log('User ID:', this.$route.query.userId);
  console.log('Formatted Date:', this.$route.query.formattedDate);
  console.log('Number of Operations:', this.$route.query.numOperations);
  console.log('Total Amount:', this.$route.query.totalAmount);
  console.log('Commission Amount:', this.$route.query.commissionAmount);
  
    this.userId = this.$route.query.userId || 'Usuario Desconocido';
    this.formattedDate = this.$route.query.formattedDate || new Date().toLocaleString();
    this.numOperations = parseInt(this.$route.query.numOperations) || 0;
    this.totalAmount = parseFloat(this.$route.query.totalAmount) || 0;
    this.commissionAmount = parseFloat(this.$route.query.commissionAmount) || 0;

    
  }
};
</script>

<style scoped>
.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
  margin-top: 10px;
}
</style>
