<template>
  <div class="datoscliente">
    <h3>Resumen</h3>
    <p><strong>Usuario:</strong> {{ userId }}</p>
    <p><strong>Número de operaciones abiertas:</strong> {{ numOperations }}</p>
    <p><strong>Monto total:</strong> ${{ totalAmount.toFixed(2) }}</p>
    <p><strong>Monto por comisión:</strong> ${{ commissionAmount.toFixed(2) }}</p>
    <p><strong>Monto a rendir:</strong> ${{ (totalAmount - commissionAmount).toFixed(2) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      required: true
    },
    numOperations: {
      type: Number,
      required: false,
      default: 0
    },
    totalAmount: {
      type: Number,
      required: false,
      default: 0
    },
    commissionAmount: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>

<style scoped>
.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
  margin-top: 10px;
}
</style>
