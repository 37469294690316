<template>
  <div class="listar-operaciones">
    <main class="container">
      <div class="logo-wrapper logo-small header-low">
        <div class="image-container">
          <img src="../img/logo-swissntet-pdp.png" alt="Logo" />
        </div>
      </div>
      <div class="starter-template py-5 px-3">
        <div class="row">
          <!-- Filters Column -->
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h3 style="font-size:2rem">Filtrar Operaciones</h3>

                <label for="status">Estado</label>
                <select v-model="filters.status" id="status" class="form-control">
                  <option value="">Todas</option>
                  <option value="open">Abiertas</option>
                  <option value="closed">Cerradas</option>
                </select>
                <!-- Admin Only: Filter by User ID -->
                <div v-if="isAdmin">
                  <label for="userId">Usuario</label>
                  <input
                    type="text"
                    v-model="filters.userId"
                    id="userId"
                    class="form-control"
                    placeholder="Usuario"
                  />
                </div>
                <br />
                <label for="startDate">Desde</label>
                <input type="date" v-model="filters.startDate" id="startDate" class="form-control" />

                <label for="endDate">Hasta</label>
                <input type="date" v-model="filters.endDate" id="endDate" class="form-control" />

               
                <div class="btn-group">
                  <button class="btn-cancelar ml-2 rounded" @click="goHome">
                    <strong>Inicio</strong>
                  </button>
                  <button class="btn" @click="applyFilters">Aplicar Filtros</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Results Column -->
          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <ul class="list-group mb-3" v-if="operations.length > 0">
                  <li
                    v-for="operation in operations"
                    :key="operation.id"
                    class="list-group-item d-flex justify-content-between lh-condensed"
                  >
                    <div>
                      <h2 class="my-0">Operación ID: {{ operation.idoperacion }}</h2>
                      <p class="text-muted resultados"> Usuario: {{ operation.userid }}  </p>
                      <p class="text-muted resultados">  Monto: ${{ operation.importe }}  </p>
                      <p class="text-muted resultados"> Fecha: {{ operation.fecha }}  </p>
                      <p class="text-muted resultados">   Estado: {{ operation.status }}  </p>
                     
                    </div>
                  </li>
                </ul>
                <div v-else>
                  <p>No se encontraron operaciones.</p>
                </div>
                <!-- Pagination -->
                <div class="pagination">
                  <button
                    class="btn"
                    @click="changePage(page)"
                    v-for="page in totalPages"
                    :key="page"
                    :disabled="page === currentPage"
                  >
                    {{ page }}
                  </button>
                </div>
                <div v-if="errorMessage">{{ errorMessage }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operations: [],
      totalPages: 0,
      currentPage: 1,
      filters: {
        status: '',
        startDate: '',
        endDate: '',
        userId: ''
      },
      errorMessage: ''
    };
  },
  computed: {
    isAdmin() {
      return localStorage.getItem('userRole') === 'admin';
    }
  },
  
  methods: {
  goHome() {
    this.$router.push('/');
  },
  async fetchOperations(page = 1) {
    try {
      const params = new URLSearchParams();
      params.append('page', page);
      params.append('itemsPerPage', 10);

      if (this.filters.status) params.append('status', this.filters.status);
      if (this.filters.startDate) params.append('startDate', this.filters.startDate);
      if (this.filters.endDate) params.append('endDate', this.filters.endDate);
      if (this.isAdmin && this.filters.userId) params.append('userId', this.filters.userId);

      // Debug log to verify the constructed API URL
      console.log('Fetching with params:', params.toString());

      const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones?${params}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to fetch operations');
      
      const data = await response.json();

      // Debug log to verify the API response
      console.log('API Response:', data);

      this.operations = data.operations;
      this.totalPages = data.totalPages;
      this.currentPage = page;

      // Debug log to ensure that the state is being set properly
      console.log('Updated state - operations:', this.operations);
      console.log('Updated state - totalPages:', this.totalPages);
      console.log('Updated state - currentPage:', this.currentPage);

    } catch (error) {
      console.error('Error fetching operations:', error);
      this.errorMessage = 'Failed to fetch operations';
    }
  },
  changePage(page) {
    this.fetchOperations(page);
  },
  applyFilters() {
    // Always reset to page 1 when filters are applied
    this.fetchOperations(1);
    
  }
}
,
  mounted() {
    this.fetchOperations();
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

.list-group-item {
  background-color: #dddddd;
  border-color: #000;
}

.pagination button {
  margin: 5px;
}

.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
}

.card-body {
  padding: 20px;
}
</style>
