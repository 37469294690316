<template>
  <div class="d-flex flex-column h-100 text-center">
    <main class="container">
      <div class="logo-wrapper logo-small">
        <div class="image-container">
          <img src="../img/logo-swissntet-pdp.png" alt="Logo" />
        </div>
      </div>
      <div class="starter-template text-center py-5 px-3">
        <div class="row">
          <!-- Receipt Column -->
          <div class="col-md-8">
            <div class="card text-center">
              <div class="card-body">
                <h5 class="card-title">Resumen de Cierre de Caja</h5>
                <div id="cierreReceipt">
                  <center>
                    <h2>SWISSNET</h2>
                    José Rivera 440<br />
                    Jardín América - Misiones<br />
                    info@swiss-net.com.ar<br />
                    <hr>
                  </center>
                  <!-- ResumenCierreCajaReceipt Component --><!-- Pass userId from closureSummary -->
                  <ResumenCierreCajaReceipt
                    :user-id="closureSummary.userId"  
                    :formatted-date="formattedDate"
                    :num-operations="closureSummary.numOperations"
                    :total-amount="closureSummary.totalAmount"
                    :commission-amount="closureSummary.commissionAmount"
                  ></ResumenCierreCajaReceipt>
                </div>
              </div>
            </div>
          </div>

          <!-- Buttons Column -->
          <div class="col-md-4 d-flex flex-column justify-content-center">
            <button @click="printReceipt" class="btn btn-primary mb-3">Imprimir Resumen</button>
            <button @click="finishTransaction" class="btn btn-secondary">Finalizar</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ResumenCierreCajaReceipt from '@/components/ResumenCierreCajaReceipt.vue'; // Import the component
import printJS from 'print-js';

export default {
  components: {
    ResumenCierreCajaReceipt, // Register the component
  },
  data() {
    return {
      closureSummary: JSON.parse(localStorage.getItem('closureSummary')) || {},
      formattedDate: new Date().toLocaleString('es-AR'),
    };
  },
  methods: {
    autoPrint() {
      // Delay the print action slightly to ensure the page is fully rendered
      setTimeout(() => {
        this.printReceipt();
      }, 500); // Adjust the delay if needed
    },
    printReceipt() {
      printJS({
        printable: 'cierreReceipt',
        type: 'html',
      });
    },
    finishTransaction() {
      // Clear localStorage and redirect to the dashboard
      localStorage.removeItem('closureSummary');
      this.$router.push('/cierreDeCaja');
    },
  },
  mounted() {
    // Trigger the print dialog automatically when the page loads
    this.autoPrint();
  },
};
</script>

<style scoped>
.receipt-container {
  margin: 20px;
}
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/login.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';
</style>
