<template>
  <div class="text-center bodyUno" v-if="isLoggedIn">
    <main class="container">
      <div class="starter-template text-center py-1 px-3">
        <div class="logo-wrapper">
          <div class="image-container">
            <img src="../img/logo-swissntet-pdp.png" alt="Logo">
          </div>
        </div>

        <div class="card text-center">
          <div class="card-body">
            <h1 class="card-title">Puntos De Pago - Inicio</h1>

            <div class="btn-group-vertical">
              <button class="btn mr-2 mb-2" @click="goTo('seleccionaCliente')">
                <strong>&nbsp;&nbsp;&nbsp;Pagar&nbsp;&nbsp;&nbsp;</strong>
              </button>
              <br>
              <button id="listOperationsBtn" class="btn mr-2 mb-2" @click="goTo('listarOperaciones')" >
                <strong>&nbsp;&nbsp;&nbsp;Listar Operaciones&nbsp;&nbsp;&nbsp;</strong>
              </button> <br>
              
              <button class="btn mr-2 mb-2" @click="goTo('cierreDeCaja')">
                <strong>&nbsp;&nbsp;&nbsp;Cierre de Caja&nbsp;&nbsp;&nbsp;</strong>
              </button>
              <br>
            </div>
          </div>
        </div>
      </div>
    </main>

        <div class="modal fade" id="ModalProblemaConexion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <img src="@/img/errorIcon.png" width="15%"><br><br>
            En estos momentos no es posible conectar con el servidor.<br><br>
            <button type="button" class="btn" data-dismiss="modal" @click="reloadPage">
              <strong>&nbsp;&nbsp;REINTENTAR&nbsp;&nbsp;&nbsp;</strong>
            </button><br><br>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  data() {
    return {
      userRole: '',
      isLoggedIn: false,
      apiKeyIspcube: '',
      apiTokenIspcube: ''
    };
  },
  computed: {
    isAdmin() {
      return this.userRole === 'admin';
    }
  },
  methods: {
    async checkSession() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/checkSession`, {
          method: 'GET',
          credentials: 'include',
        });

        const data = await response.json();

        if (data.loggedin) {
          this.isLoggedIn = true;
          this.userRole = localStorage.getItem('userRole') || 'defaultRole'; // Set from localStorage
          localStorage.setItem('userRole', this.userRole);

          // Retrieve external API credentials from localStorage
          this.apiKeyIspcube = localStorage.getItem('apiKeyIspcube');
          this.apiTokenIspcube = localStorage.getItem('apiTokenIspcube');
          console.log('API Key ISPcube:', this.apiKeyIspcube);
          console.log('API Token ISPcube:', this.apiTokenIspcube);
        } else {
          this.$router.push('/login'); // Redirect to login if not logged in
        }
      } catch (error) {
        console.error('Error checking session:', error);
        this.$router.push('/login'); // Redirect to login on error
      }
    },
    goTo(path) {
      this.$router.push({ path: `/${path}` });
    },
    reloadPage() {
      window.location.reload();
    },
    initializePage() {
      console.log('initializePage called');
    }
  },
  async mounted() {
    await this.checkSession();
    if (this.isLoggedIn) {
      this.initializePage();
    }
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';
.logo-wrapper {
  max-width: 340px; /* Adjust this value to control the maximum size of the logo */
  margin: 0 auto;
}
</style>
