<template>
  <div class="d-flex flex-column h-100 text-center">
    <main class="container">
      <div class="logo-wrapper logo-small">
        <div class="image-container">
          <img src="../img/logo-swissntet-pdp.png" alt="Logo">
        </div>
      </div>

      <div class="starter-template text-center py-5 px-3">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">Carga Finalizada</h5>
            <div class="col-md-4 order-md-2 mb-4" style="max-width: 100rem;">
              <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h2 class="card-title">Saldo Inicial:</h2>
                  </div>
                  <h2 class="card-title">{{ saldoInicial }}</h2>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h2 class="card-title">Total Depositado:</h2>
                  </div>
                  <h2 class="card-title">{{ totalDepositado }}</h2>
                </li>
              </ul>
            </div>
            <div class="row begin-countdown">
              <div class="col-md-12 text-center">
                <progress :value="countdown" max="5"></progress>
                <p>Redireccionando en <span>{{ countdown }}</span> segundos...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import * as funcionesCompartidas from '@/utils/funcionesCompartidas.js';
import { realizaDeposito } from '@/utils/funcionesCompartidas.js';

export default {
  data() {
    return {
      saldoInicial: localStorage.getItem('clienteDeuda') || 0,
      totalDepositado: parseFloat(localStorage.getItem('totalDepositado')) || 0,
      countdown: 5,
      hayConexion: localStorage.getItem('hayConexion') || 'false',
      paymentProcessed: false
    };
  },
  methods: {
    async processPayment() {
      console.log("processPayment called");

      this.totalDepositado = parseFloat(localStorage.getItem('totalDepositado')) || 0;

      try {
        await funcionesCompartidas.chequeaServidor();
        console.log("Server connection confirmed.");
        this.hayConexion = 'true';
      } catch (error) {
        console.warn("Server connection failed. Proceeding with local operations.");
        this.hayConexion = 'false';
      }

      if (this.hayConexion === 'true') {
        try {
          await funcionesCompartidas.procesaDeposito();
          console.log("Remote payment successful.");
          localStorage.setItem('estadoDeposito', 'realizado');
          
          await this.createOperation(); 
          console.log("Operation inserted locally.");
        } catch (error) {
          console.error("Remote payment or local operation failed:", error);
          localStorage.setItem('estadoDeposito', 'pendiente');
        }
      } else {
        funcionesCompartidas.postergaDeposito();
        console.log("Payment postponed, no server connection.");
      }

      this.startCountdown();
    },

    async createOperation() {
      console.log("createOperation called");

      const operationState = localStorage.getItem('estadoDeposito');
      if (operationState === 'realizado') {
          console.log("Operation already marked as completed, skipping insertion.");
          return;
      }

      const userid = localStorage.getItem('userid') || 'totem';
      const idcustomer = localStorage.getItem('clienteIdCustomer');
      const codigocobranza = localStorage.getItem('codigoCobranzaDevuelto');
      const fecha = new Date().toISOString();
      const importe = this.totalDepositado;

      console.log({ userid, idcustomer, fecha, codigocobranza, importe });

      if (!codigocobranza) {
          console.error("Missing codigocobranza, aborting operation creation");
          return;
      }

      try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              credentials: 'include',
              body: JSON.stringify({ userid, idcustomer, fecha, codigocobranza, importe })
          });

          if (response.status === 409) {
              console.warn("Operation already exists, skipping insertion.");
              return;
          }

          if (!response.ok) throw new Error('Error creating operation');
          console.log('Operation created successfully');
          localStorage.setItem('estadoDeposito', 'realizado');
      } catch (error) {
          console.error('Error creating operation:', error);
      }
    },

    startCountdown() {
      const countdownInterval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(countdownInterval);
          this.redirectAfterCountdown();
        }
      }, 1000);
    },

    redirectAfterCountdown() {
      this.$router.push('/pagorealizado');
    },

    async retryPendingPayment() {
      const clienteIdCustomer = localStorage.getItem("clienteIdCustomer");
      const totalDepositado = parseFloat(localStorage.getItem("totalDepositado")) || 0;

      try {
        console.log("Retrying pending payment.");
        await realizaDeposito(clienteIdCustomer, totalDepositado);
        localStorage.setItem('estadoDeposito', 'realizado');  
        console.log("Payment retried successfully.");

        const userid = localStorage.getItem('userid'); 
        const codigocobranza = localStorage.getItem('codigoCobranzaDevuelto');
        const fecha = new Date().toISOString();
        const importe = totalDepositado;

        const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ userid, clienteIdCustomer, fecha, codigocobranza, importe })
        });

        if (!response.ok) throw new Error('Error retrying operation');
        console.log("Operation retried successfully.");
      } catch (error) {
        console.error("Failed to retry payment:", error);
      }
    }
  },

  mounted() {
    if (!this.paymentProcessed) {
      this.paymentProcessed = true;
      funcionesCompartidas.chequeaServidor().then(() => {
        if (localStorage.getItem('estadoDeposito') === 'pendiente') {
          this.retryPendingPayment();
        } else {
          this.processPayment(); 
        }
      });
    }
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';
</style>
