<template>
  <main class="container ">
      <div class="starter-template text-center py-1 px-3">
        <div class="logo-wrapper">
          <div class="image-container">
            <img src="../img/logo-swissntet-pdp.png" alt="Logo">
          </div>
        </div>
        <div class="card text-center">
          <div class="card-body">

              <div class="login-container">
                <h1 class="card-title">Puntos de Pago Login</h1>
                <form @submit.prevent="loginUser">
                  <div style="margin: 0.5rem;">
                    <label for="username">Usuario:</label>
                    <input type="text" v-model="username" id="username" required />
                  </div>
                  <div style="margin: 0.5rem;">
                    <label for="password">Contraseña:</label>
                    <input type="password" v-model="password" id="password" required />
                  </div>
                  <button type="submit" class="btn ">Login</button>
                </form>
                <p v-if="errorMessage">{{ errorMessage }}</p>
              </div>
        </div>        
      </div>

    
      </div>
    </main>

</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nombre: this.username,
            password: this.password
          }),
          credentials: 'include' // Ensures cookies are sent and received
        });

        const data = await response.json();
        console.log('Login response:', data);  // Debugging the response

        if (data.success) {
          // Save the tokens and other data to localStorage
          localStorage.setItem('apiKeyIspcube', data.apiKeyIspcube);  // Adjusted for camelCase
          localStorage.setItem('apiTokenIspcube', data.apiTokenIspcube);  // Adjusted for camelCase
          localStorage.setItem('apiKeyPuntosdepago', data.apiKeyPuntosdepago);  // Adjusted for camelCase
          localStorage.setItem('userid', data.userid);
          localStorage.setItem('userRole', data.userRole);  // Store userRole if returned

          // Redirect to the home page
          this.$router.push('/');
        } else {
          this.errorMessage = data.message;
        }
      } catch (error) {
        this.errorMessage = 'An error occurred during login. Please try again.';
      }
    }
  }
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';
.logo-wrapper {
  max-width: 340px; /* Adjust this value to control the maximum size of the logo */
  margin: 0 auto;
}</style>
