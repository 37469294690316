import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';  // Import the login page
import HomePage from '@/views/HomePage.vue';  // Adjust the import paths as needed
import SeleccionaCliente from '@/views/SeleccionaCliente.vue';  // Ensure this path is correct
import ConfirmaCliente from '@/views/ConfirmaCliente.vue';
import PagarDeuda from '@/views/PagarDeuda.vue';
import RealizarPago from '@/views/RealizarPago.vue';
import PagoRealizado from '@/views/PagoRealizado.vue';
import CierreDeCaja from '@/views/CierreDeCaja.vue';
import CierreDeCajaReceipt from '@/views/CierreDeCajaReceipt.vue';
import ListarOperaciones from '@/views/ListarOperaciones.vue';
import GraciasPage from '@/views/GraciasPage.vue';
import ReciboPage from '@/views/ReciboPage.vue';


const routes = [
  { path: '/login', component: LoginPage },  // Add the login route
  { path: '/', name: 'Home', component: HomePage, meta: { requiresAuth: true } },
  { path: '/seleccionaCliente', name: 'SeleccionaCliente', component: SeleccionaCliente, meta: { requiresAuth: true } },
  { path: '/confirmaCliente', name: 'ConfirmaCliente', component: ConfirmaCliente },
  { path: '/pagarDeuda', name: 'PagarDeuda', component: PagarDeuda },
  { path: '/realizarPago', name: 'RealizarPago', component: RealizarPago },
  { path: '/pagorealizado', name: 'PagoRealizado', component: PagoRealizado },
  { path: '/cierreDeCaja', name: 'CierreDeCaja', component: CierreDeCaja, meta: { requiresAuth: true } },
  { path: '/cierreDeCajaReceipt', name: 'CierreDeCajaReceipt', component: CierreDeCajaReceipt },
  { path: '/listarOperaciones', name: 'ListarOperaciones', component: ListarOperaciones },
  { path: '/gracias', name: 'Gracias', component: GraciasPage  },
  { path: '/recibo', name: 'Recibo', component: ReciboPage  },



];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!localStorage.getItem('userid');
  const userRole = localStorage.getItem('userRole');

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else if (to.matched.some(record => record.meta.adminOnly) && userRole !== 'admin') {
    next('/'); // Redirect non-admins back to home
  } else {
    next();
  }
});

export default router;