<template>
  <div class="d-flex flex-column h-100 text-center">
    <main class="container">
      <div class="logo-wrapper logo-small">
        <div class="image-container">
          <img src="../img/logo-swissntet-pdp.png" alt="Logo" />
        </div>
      </div>
      <div class="starter-template text-center py-1 px-3">
        <div class="card text-center">
          <div class="card-body">
            <h1 class="card-title">Confirmar titular de la cuenta</h1>
            <div class="datoscliente">
              <h1 class="card-title"> Cliente: {{ clienteNombre }}</h1>
              <br />
              <h2>
                Saldo actual:
                <strong :style="{ color: deudaColor }">{{ clienteDeuda }}</strong>
              </h2>
            </div>

            <p>
              <br /><br />
              <center>
                <!-- When user clicks 'VOLVER A INTENTAR', we clear localStorage -->
                <button class="btn-cancelar ml-2 rounded" @click="volverAIntentar">
                  <strong>&nbsp;&nbsp;VOLVER A INTENTAR&nbsp;&nbsp;</strong>
                </button>

                <!-- When user confirms, we just navigate without clearing localStorage -->
                <button class="btn btn-success mr-2 rounded" @click="goTo('pagarDeuda')">
                  <strong>&nbsp;&nbsp;CONFIRMAR&nbsp;&nbsp;</strong>
                </button>
              </center>
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clienteNombre: '',
      clienteDeuda: '',
      deudaColor: '',
    };
  },
  methods: {
    // Go to the specified route without clearing localStorage
    goTo(route) {
      this.$router.push(`/${route}`);
    },

    // This method clears the localStorage and navigates back to SeleccionaCliente
    volverAIntentar() {
      this.clearLocalStorage();
      this.$router.push('/seleccionaCliente');
    },

    // Clear related localStorage items
    clearLocalStorage() {
      localStorage.removeItem('usernameingresado');
      localStorage.removeItem('clienteNombre');
      localStorage.removeItem('clienteDeuda');
      localStorage.removeItem('clienteId');
      localStorage.removeItem('clienteIdCustomer');
    },

    initializeClienteData() {
      this.clienteNombre = localStorage.getItem('clienteNombre');
      const deuda = parseFloat(localStorage.getItem('clienteDeuda'));
      this.clienteDeuda = `$ ${deuda.toFixed(2)}`;
      this.deudaColor = deuda >= 0 ? 'blue' : 'red';
    }
  },
  mounted() {
    this.initializeClienteData();
  },

  // Handle when user navigates away from ConfirmaCliente
  beforeRouteLeave(to, from, next) {
    // Check if user is navigating to `pagarDeuda` or if it's a backward/other navigation
    if (to.path === '/pagarDeuda' || to.name === 'pagarDeuda') {
      // If user is going to `pagarDeuda`, don't clear localStorage
      next();  
    } else {
      // If navigating to any other page or back to `SeleccionaCliente`, clear localStorage
      this.clearLocalStorage();
      next();
    }
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

/* Additional styles specific to this component */
</style>
